import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import labels from "./labels";

const LoginPage = () => {

  const [language, setLanguage] = useState('en');
  const [currentLabels, setCurrentLabels] = useState(labels[language]);

  useEffect(() => {
    // Recupera la lingua salvata o usa quella del browser
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
    const initialLanguage = savedLanguage || browserLanguage;
    setLanguage(initialLanguage);
    setCurrentLabels(labels[initialLanguage]);
  }, []);

  useEffect(() => {
    // Aggiorna le etichette quando cambia la lingua salvata dal Footer
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && savedLanguage !== language) {
      setLanguage(savedLanguage);
      setCurrentLabels(labels[savedLanguage]);
    }
  }, [language]);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [feedbackMessage, setFeedbackMessage] = useState({ type: '', text: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    const errors = { ...formErrors };

    if (name === 'email') {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!value || !emailRegex.test(value)) {
        errors.email = 'Please enter a valid email address.';
      } else {
        delete errors.email;
      }
    }

    if (name === 'password') {
      if (!value || value.length < 8) {
        errors.password = 'Password must be at least 8 characters long.';
      } else {
        delete errors.password;
      }
    }

    setFormErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(formErrors).length > 0) return;

    try {
      const response = await axios.post('/api/login', formData, {
        withCredentials: true,
      });

      const { user } = response.data;

      setFeedbackMessage({ type: 'success', text: currentLabels.successLogin });
      setTimeout(() => {
        navigate(user.active ? '/home' : '/products');
      }, 1000);
    } catch (error) {
      setFeedbackMessage({
        type: 'error',
        text: error.response ? error.response.data.error : currentLabels.failedLogin,
      });
    }
  };

  return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">{currentLabels.login}</h2>
          {feedbackMessage.text && (
              <div
                  className={`text-sm p-3 mb-4 text-white rounded ${
                      feedbackMessage.type === 'success' ? 'bg-green-500' : 'bg-red-500'
                  }`}
              >
                {feedbackMessage.text}
              </div>
          )}
          <form onSubmit={handleSubmit}>
            {/* Email */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.email}</label>
              <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.email ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.email && <p className="text-sm text-red-500">{formErrors.email}</p>}
            </div>

            {/* Password */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.password}</label>
              <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.password ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.password && <p className="text-sm text-red-500">{formErrors.password}</p>}
            </div>

            {/* Submit Button */}
            <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none"
            >
              {currentLabels.login}
            </button>
          </form>

          <p className="mt-4 text-sm text-center">
            {currentLabels.notHavingAccount}{' '}
            <Link to="/register" className="text-blue-600 hover:underline">
              {currentLabels.register}
            </Link>
          </p>
        </div>
      </div>
  );
};

export default LoginPage;
