import React, {useEffect, useState} from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import logo from './images/logo.svg';
import labels from "./labels"; // Importa il logo

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const [language, setLanguage] = useState('en');
  const [currentLabels, setCurrentLabels] = useState(labels[language]);

  useEffect(() => {
    // Recupera la lingua salvata o usa quella del browser
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
    const initialLanguage = savedLanguage || browserLanguage;
    setLanguage(initialLanguage);
    setCurrentLabels(labels[initialLanguage]);
  }, []);

  useEffect(() => {
    // Aggiorna le etichette quando cambia la lingua salvata dal Footer
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && savedLanguage !== language) {
      setLanguage(savedLanguage);
      setCurrentLabels(labels[savedLanguage]);
    }
  }, [language]);

  // Verifica se il cookie 'sessionwebauth' esiste
  const sessionCookie = Cookies.get('sessionwebauth');

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove('sessionwebauth');
    Cookies.remove('user');
    Cookies.remove('token');
    navigate('/'); // Redirect alla home
  };

  const handleLogin = () => {
    navigate('/login');
  };
  const handleMatchSelector = () => {
    navigate('/match');
  };
  const handlePredictions = () => {
    navigate('/');
  };
  const handleRegister = () => {
    navigate('/register');
  };

  const handleLogoClick = () => {
    navigate('/'); // Reindirizza alla home quando il logo viene cliccato
  };

  return (
      <AppBar position="static" sx={{ bgcolor: '#2E3B55' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Spaziatore a sinistra per spingere il logo verso il centro */}
          <Box sx={{ flexGrow: 1 }}></Box>

          {/* Logo centrato */}
          <Box sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            <img
                src={logo}
                alt="Logo"
                onClick={handleLogoClick}
                style={{
                  height: '40px',
                  cursor: 'pointer',
                  filter: 'invert(1) brightness(100%)', // Filtro per renderlo bianco
                }}
            />
          </Box>

          {/* Icona utente sulla destra */}
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
                color="inherit"
                onClick={handleMenuOpen}
                edge="end"
            >
              <AccountCircle />
            </IconButton>
              <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
              >
                  {/* Se il cookie esiste, mostra Logout, altrimenti Login e Registrati */}
                  {sessionCookie ? (
                      <>
                          <MenuItem onClick={handlePredictions}>{currentLabels.predictions}</MenuItem>
                          <MenuItem onClick={handleLogout}>{currentLabels.logout}</MenuItem>
                      </>
                  ) : (
                      <>
                          <MenuItem onClick={handleLogin}>{currentLabels.login}</MenuItem>
                          <MenuItem onClick={handleRegister}>{currentLabels.register}</MenuItem>
                      </>
                  )}
              </Menu>
          </Box>
        </Toolbar>
      </AppBar>
  );
};

export default Header;
