import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const CheckoutPage = () => {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionIdFromURL = params.get('sessionId');
    if (sessionIdFromURL) {
      setSessionId(sessionIdFromURL);
    }
  }, []);

  if (!sessionId) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <h2>Pagamento</h2>
      {}
      <iframe
        src={`https://checkout.stripe.com/pay/${sessionId}`}
        width="100%"
        height="100%"
        frameBorder="0"
        allow="payment"
        data-turbo="false"
        title="Stripe Checkout"
      ></iframe>
    </div>
  );
};

export default CheckoutPage;
