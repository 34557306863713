import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MatchSelector from './MatchSelector'; // Import MatchSelector component

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [passwordModification, setPasswordModification] = useState({
        email: null,
        newPassword: '',
        isModalOpen: false,
        passwordError: '',
    });

    const [expirationModification, setExpirationModification] = useState({
        email: null,
        newExpirationTimestamp: '',
        isModalOpen: false,
    });

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await axios.get('/api/users');
            setUsers(response.data);
        };
        fetchUsers();
    }, []);

    const handleModifyPassword = (email) => {
        setPasswordModification({ email, newPassword: '', isModalOpen: true, passwordError: '' });
    };

    const handleModifyExpirationTimestamp = (email) => {
        const user = users.find((u) => u.email === email);
        setExpirationModification({
            email,
            newExpirationTimestamp: user ? user.is_active_until : '', // Set to empty string if null
            isModalOpen: true,
        });
    };

    const saveNewPassword = async () => {
        if (passwordModification.email && passwordModification.newPassword.length >= 8) {
            await axios.patch(`/api/users/${passwordModification.email}/password`, {
                newPassword: passwordModification.newPassword,
            });

            setPasswordModification({ email: null, newPassword: '', isModalOpen: false, passwordError: '' });
            const response = await axios.get('/api/users');
            setUsers(response.data);
        }
    };

    const saveNewExpirationTimestamp = async () => {
        if (expirationModification.email) {
            await axios.patch(`/api/users/${expirationModification.email}/expiration`, {
                newExpirationTimestamp: expirationModification.newExpirationTimestamp,
            });

            setExpirationModification({ email: null, newExpirationTimestamp: '', isModalOpen: false });
            const response = await axios.get('/api/users');
            setUsers(response.data);
        }
    };

    const toggleAdminStatus = async (email) => {
        await axios.patch(`/api/users/${email}/admin-status`);
        const response = await axios.get('/api/users');
        setUsers(response.data);
    };

    const closePasswordModal = () => {
        setPasswordModification({ email: null, newPassword: '', isModalOpen: false, passwordError: '' });
    };

    const closeExpirationModal = () => {
        setExpirationModification({ email: null, newExpirationTimestamp: '', isModalOpen: false });
    };

    const formatTimestamp = (timestamp) => {
        return timestamp ? new Date(timestamp).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        }) : ''; // Returns an empty string if there's no timestamp
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPasswordModification((prev) => ({
            ...prev,
            newPassword,
            passwordError: newPassword.length < 8 ? 'Password must be at least 8 characters long' : '',
        }));
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
            <div className="bg-white shadow-md rounded-lg p-6">
                <h1 className="text-2xl font-bold mb-6 text-gray-800">User Management Dashboard</h1>
                <div className="overflow-x-auto">
                    <table className="w-full border-collapse">
                        <thead>
                        <tr className="bg-gray-200">
                            <th className="border p-3 text-left">First Name</th>
                            <th className="border p-3 text-left">Last Name</th>
                            <th className="border p-3 text-left">Phone Number</th>
                            <th className="border p-3 text-left">Email</th>
                            <th className="border p-3 text-left">Password</th>
                            <th className="border p-3 text-left">Invoice URL</th>
                            <th className="border p-3 text-left">Admin Status</th>
                            <th className="border p-3 text-left">Expiration Timestamp</th>
                            <th className="border p-3 text-left">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map((user) => (
                            <tr key={user.email} className="hover:bg-gray-100">
                                <td className="border p-3">{user.first_name}</td>
                                <td className="border p-3">{user.last_name}</td>
                                <td className="border p-3">{user.phone_number}</td>
                                <td className="border p-3">{user.email}</td>
                                <td className="border p-3">{'*'.repeat(user.password.length)}</td>
                                <td className="border p-3">
                                    <a
                                        href={user.invoice_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:underline"
                                    >
                                        View Invoice
                                    </a>
                                </td>
                                <td className="border p-3">
                                    <button
                                        onClick={() => toggleAdminStatus(user.email)}
                                        className={`px-3 py-1 rounded ${user.is_admin ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
                                    >
                                        {user.is_admin ? 'Admin' : 'User'}
                                    </button>
                                </td>
                                <td className="border p-3">{formatTimestamp(user.is_active_until)}</td>
                                <td className="border p-3 space-y-2">
                                    <button
                                        onClick={() => handleModifyPassword(user.email)}
                                        className="block w-full bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors"
                                    >
                                        Change Password
                                    </button>
                                    <button
                                        onClick={() => handleModifyExpirationTimestamp(user.email)}
                                        className="block w-full bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-600 transition-colors"
                                    >
                                        Change Expiration
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="bg-white shadow-md rounded-lg p-6 mt-6">
                <h2 className="text-xl font-bold mb-4 text-gray-800">Match Selector</h2>
                <MatchSelector />
            </div>

            {expirationModification.isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-xl p-6 w-96">
                        <h2 className="text-xl font-bold mb-4">Change Expiration Timestamp</h2>
                        <div className="mb-4">
                            <label htmlFor="new-expiration" className="block text-sm font-medium text-gray-700 mb-2">
                                New Expiration Timestamp
                            </label>
                            <input
                                type="datetime-local"
                                id="new-expiration"
                                value={expirationModification.newExpirationTimestamp ? expirationModification.newExpirationTimestamp.slice(0, 16) : ''}
                                onChange={(e) =>
                                    setExpirationModification((prev) => ({
                                        ...prev,
                                        newExpirationTimestamp: `${e.target.value}:00`,
                                    }))
                                }
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={closeExpirationModal}
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={saveNewExpirationTimestamp}
                                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {passwordModification.isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-xl p-6 w-96">
                        <h2 className="text-xl font-bold mb-4">Change Password</h2>
                        <div className="mb-4">
                            <label htmlFor="new-password" className="block text-sm font-medium text-gray-700 mb-2">
                                New Password
                            </label>
                            <input
                                type="password"
                                id="new-password"
                                value={passwordModification.newPassword}
                                onChange={handlePasswordChange}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            {passwordModification.passwordError && (
                                <p className="text-red-500 text-sm mt-2">{passwordModification.passwordError}</p>
                            )}
                        </div>
                        <div className="flex justify-end space-x-2">
                            <button
                                onClick={closePasswordModal}
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={saveNewPassword}
                                disabled={!!passwordModification.passwordError}
                                className={`px-4 py-2 rounded ${
                                    passwordModification.passwordError
                                        ? 'bg-gray-300 text-gray-500'
                                        : 'bg-blue-500 text-white hover:bg-blue-600'
                                } transition-colors`}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};

export default AdminDashboard;
