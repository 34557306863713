import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Container, Typography, Grid, Box, Card, CardContent, CardMedia } from '@mui/material';
import productImage from './images/product.svg';
import labels from "./labels";

const ProductsPage = () => {
  const [products, setProducts] = useState([]);

    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);

    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

  // Fetch dei prodotti dal backend con invio dei cookie
  useEffect(() => {
    axios.get('/api/getAllProducts', {
      withCredentials: true, // Invia i cookie
    })
      .then(response => {
        setProducts(response.data); // Imposta i prodotti ricevuti dalla API
      })
      .catch(error => console.error('Errore nel recupero dei prodotti:', error));
  }, []);

  // Funzione per gestire il pagamento
  const handlePurchase = (priceId) => {
    // Chiamata al backend per creare la sessione di pagamento, inviando solo il priceId
    axios.post('/api/createCheckoutSession', {
      priceId: priceId,
    }, {
      withCredentials: true, // Invia i cookie
    })
      .then(response => {

        const redirectLink = response.data.url;
        if (redirectLink) {
          window.location.href = redirectLink;
        }
      })
      .catch(error => {
        console.error('Errore nella creazione della sessione di pagamento:', error);
      });
  };

  return (
    <Container maxWidth="lg" sx={{ paddingTop: 5 }}>
        <Typography variant="h3" gutterBottom align="center" color="primary" marginBottom={10} fontWeight="bold">
            {currentLabels.availablePlans}
        </Typography>

      {/* Centrare la lista dei prodotti */}
      <Grid container spacing={3} justifyContent="center">
        {products.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.product.id}>
            <Card sx={{ maxWidth: 345, boxShadow: 3, borderRadius: 2, transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.05)' } }}>
              <CardMedia
                component="img"
                alt="Product Image"
                height="180"
                image={productImage}
                title={item.product.name}
                sx={{ objectFit: 'contain', padding: 2 }}
              />
              <CardContent>
                <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>
                  {item.product.name}
                </Typography>
                <Typography variant="body2" align="center" color="textSecondary" paragraph>
                  {item.product.description}
                </Typography>
                <Typography variant="body2" align="center" color="primary">
                    {currentLabels.price}: €{item.prices[0].amount} ({item.prices[0].recurring})
                </Typography>
              </CardContent>
              <Box display="flex" justifyContent="center" sx={{ marginBottom: 2 }}>
                {/* Pulsante per gestire l'acquisto, invia solo il priceId */}
                <Button
                variant="contained"
                color="primary"
                sx={{ width: '70%', marginBottom: 2 }}
                onClick={() => handlePurchase(item.prices[0].priceId)} 
                >
                    {currentLabels.buy}
                </Button>

              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductsPage;
