import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Alert } from '@mui/material';
import labels from "./labels";

const ErrorPayment = () => {

  const [language, setLanguage] = useState('en');
  const [currentLabels, setCurrentLabels] = useState(labels[language]);

  useEffect(() => {
    // Recupera la lingua salvata o usa quella del browser
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
    const initialLanguage = savedLanguage || browserLanguage;
    setLanguage(initialLanguage);
    setCurrentLabels(labels[initialLanguage]);
  }, []);

  useEffect(() => {
    // Aggiorna le etichette quando cambia la lingua salvata dal Footer
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && savedLanguage !== language) {
      setLanguage(savedLanguage);
      setCurrentLabels(labels[savedLanguage]);
    }
  }, [language]);

  return (
      <Container maxWidth="sm" sx={{ paddingTop: 4 }}>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom>
            {currentLabels.payment_failed}
          </Typography>
          <Typography variant="h6" paragraph>
            {currentLabels.payment_failed_description}
          </Typography>
          <Typography variant="body1" paragraph>
            {currentLabels.payment_failed_instructions}
          </Typography>

          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {currentLabels.payment_error_message}
          </Alert>

          <Alert severity="info" sx={{ marginBottom: 2 }}>
            <strong>{currentLabels.need_help}</strong> {currentLabels.contact_support} <strong>{currentLabels.support_email}</strong>.
          </Alert>

          <Box sx={{ marginTop: 2 }}>
            <Button variant="outlined" color="secondary" href="/products">
              {currentLabels.retry_payment}
            </Button>
          </Box>
        </Box>
      </Container>
  );
};

export default ErrorPayment;
