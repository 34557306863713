import React, { useState, useEffect } from 'react';
import logo from "./images/logo.svg";
import labels from './labels';

const TrophyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
    </svg>
);

const ChartBarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
    </svg>
);

const ClockIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
);

const Homepage = () => {
    const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
    const [freePicksUsed, setFreePicksUsed] = useState(0);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [matches, setMatches] = useState([]);

    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);

    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                const response = await fetch('/api/daily-matches'); // Assicurati che il backend sia in esecuzione
                const data = await response.json();
                setMatches(data);
            } catch (error) {
                console.error('Error fetching daily matches:', error);
            }
        };
        fetchMatches();
    }, []);

    const handleMatchClick = (match) => {
        if (match.isPremium === 1) {
            setSelectedMatch(match); // Impostiamo il match selezionato
            setOpenPurchaseDialog(true); // Apriamo il dialogo di acquisto
        } else {
            const url = `/match?teamA=${encodeURIComponent(match.homeTeam)}&teamB=${encodeURIComponent(match.awayTeam)}&league=${encodeURIComponent(match.league)}`;
            window.location.href = url;
        }
    };

    const handleClosePurchaseDialog = () => {
        setOpenPurchaseDialog(false);
        setSelectedMatch(null);
    };

    return (
        <div className="bg-gray-50 min-h-screen text-gray-900">
            {/* Gradient Header */}
            <div className="bg-gradient-to-r from-blue-600 to-purple-700 text-white">
                <div className="container mx-auto px-4 py-16 text-center">
                    {/* Logo */}
                    <div className="mb-4">
                        <img
                            src={logo}
                            alt="GizzoBet Logo"
                            className="w-[20rem] h-auto mx-auto filter invert" // Logo aumentato del 30% in termini di larghezza
                        />
                    </div>
                    <p className="text-xl max-w-2xl mx-auto text-blue-100">
                        {currentLabels.homePageTitle}
                    </p>
                    {/* Add login and register buttons */}
                    <div className="mt-6">
                        <a href="/login">
                            <button
                                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-semibold">
                                {currentLabels.login}
                            </button>
                        </a>
                        <a href="/register">
                            <button
                                className="ml-4 px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 font-semibold">
                                {currentLabels.register}
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            {/* Matches Section */}
            <div className="container mx-auto px-4 py-12">
                <h2 className="text-3xl font-bold mb-8 text-center">{currentLabels.todaysPredictions}</h2>
                <div className="bg-white shadow-lg rounded-xl overflow-hidden">
                    <div className="overflow-x-auto"> {/* Horizontal scroll wrapper */}
                        <table className="w-full">
                            <thead className="bg-gray-100 border-b">
                            <tr>
                                <th className="p-4 text-left text-gray-600">{currentLabels.match}</th>
                                <th className="p-4 text-left text-gray-600">{currentLabels.league}</th>
                                <th className="p-4 text-left text-gray-600">{currentLabels.time}</th>
                                <th className="p-4 text-left text-gray-600">{currentLabels.status}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {matches
                                .filter((match) => match.isPremium === 0) // Filtra i match Free
                                .sort((a, b) => a.league.localeCompare(b.league)) // Ordina i match Free per lega
                                .concat(
                                    matches
                                        .filter((match) => match.isPremium === 1) // Filtra i match Premium
                                        .sort((a, b) => a.league.localeCompare(b.league)) // Ordina i match Premium per lega
                                )
                                .map((match) => (
                                    <tr
                                        key={match.id}
                                        className="hover:bg-gray-50 cursor-pointer border-b last:border-b-0"
                                        onClick={() => handleMatchClick(match)}
                                    >
                                        <td className="p-4 font-semibold">
                                            {match.homeTeam} vs {match.awayTeam}
                                        </td>
                                        <td className="p-4 text-gray-600">{match.league}</td>
                                        <td className="p-4 text-gray-600">
                                            {new Date(
                                                new Date(match.eventTime).setHours(
                                                    new Date(match.eventTime).getHours() + 1
                                                )
                                            ).toLocaleTimeString()}
                                        </td>
                                        <td className="p-4">
                                            {match.isPremium === 0 ? (
                                                <span
                                                    className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs font-semibold">
                                {currentLabels.free}
                            </span>
                                            ) : (
                                                <span
                                                    className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-xs font-semibold">
                                {currentLabels.premium}
                            </span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4 py-2">
                {/*<div className="text-center mb-12">*/}
                {/*    <button onClick={handleMatchSelector} className="px-8 py-4 bg-gradient-to-r from-blue-500 to-teal-500 text-white rounded-xl shadow-lg hover:from-blue-600 hover:to-teal-600 transform transition-all duration-300 hover:scale-105 font-semibold">*/}
                {/*        Go to Match Selector*/}
                {/*    </button>*/}
                {/*</div>*/}
                <div className="grid md:grid-cols-3 gap-8">
                    {[
                        {
                            icon: <TrophyIcon/>,
                            title: currentLabels.banner1,
                            description: currentLabels.banner1description
                        },
                        {
                            icon: <ChartBarIcon/>,
                            title: currentLabels.banner2,
                            description: currentLabels.banner2description
                        },
                        {
                            icon: <ClockIcon/>,
                            title: currentLabels.banner3,
                            description: currentLabels.banner3description
                        }
                    ].map((feature, index) => (
                        <div
                            key={index}
                            className="bg-white shadow-lg rounded-xl p-6 text-center hover:shadow-xl transition-all"
                        >
                            <div className="flex justify-center mb-4">{feature.icon}</div>
                            <h2 className="text-xl font-bold mb-3 text-gray-800">{feature.title}</h2>
                            <p className="text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            {/* Purchase Dialog */}
            {openPurchaseDialog && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-2xl shadow-2xl p-8 w-[500px] text-center">
                        <h2 className="text-3xl font-bold mb-4 text-blue-800">{currentLabels.upgrade_to_pro_title}</h2>
                        <p className="text-gray-600 mb-6 text-lg">{currentLabels.upgrade_to_pro_description}</p>
                        <div className="flex justify-center space-x-4">
                            <button
                                className="px-6 py-3 bg-gray-200 rounded-lg hover:bg-gray-300 font-semibold"
                                onClick={handleClosePurchaseDialog}
                            >
                                {currentLabels.cancel}
                            </button>
                            <button
                                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-semibold"
                                onClick={() => window.location.href = '/register'}
                            >
                                {currentLabels.view_pro_plans}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Homepage;
