import React, {useEffect, useState} from 'react';
import labels from "./labels";

const NotFoundPage = () => {

    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);

    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-bold text-gray-800">{currentLabels.page_not_found}</h1>
                <p className="mt-4 text-lg text-gray-600">
                    {currentLabels.page_not_found_description}
                </p>
                <a
                    href="/"
                    className="mt-6 inline-block px-6 py-2 text-white bg-blue-500 rounded-md shadow hover:bg-blue-600"
                >
                    {currentLabels.go_back_home}
                </a>
            </div>
        </div>
    );
};

export default NotFoundPage;
