import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, IconButton, Grid, Link, Select, MenuItem } from '@mui/material';
import { Telegram, Instagram, Email, SportsSoccer } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import labels from './labels';

const Footer = () => {
  const [language, setLanguage] = useState('en');
  const [currentLabels, setCurrentLabels] = useState(labels[language]);

  useEffect(() => {
    // Retrieve language from localStorage or set based on navigator.language
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
    const initialLanguage = savedLanguage || browserLanguage;
    setLanguage(initialLanguage);
    setCurrentLabels(labels[initialLanguage]);
  }, []);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    setCurrentLabels(labels[newLanguage]);
    localStorage.setItem('language', newLanguage);
    window.location.reload(); // Ricarica la pagina dopo il cambio lingua
  };

  return (
      <Box sx={{ bgcolor: '#2E3B55', color: 'white', py: 4, mt: 5 }}>
        <Container>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <IconButton
                  color="inherit"
                  aria-label="Football Icon"
                  href="https://gizzobet.com"
              >
                <SportsSoccer />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                  color="inherit"
                  aria-label="Telegram"
                  href="https://t.me/gizzo_bet"
              >
                <Telegram />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                  color="inherit"
                  aria-label="Instagram"
                  href="https://www.instagram.com/gizzo_bet_ro/"
              >
                <Instagram />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                  color="inherit"
                  aria-label="Email"
                  href="mailto:gizzoro.bettings@gmail.com"
              >
                <Email />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                  color="inherit"
                  aria-label="WhatsApp"
                  href="https://wa.me/message/ZJA3A4GMHMBVC1"
              >
                <WhatsAppIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            &copy; 2024 GizzoBet. {currentLabels.statistics}
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 1 }}>
            <Link href="/terms-and-conditions" color="inherit">
              {currentLabels.termsAndContitions}
            </Link>
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            <Select
                value={language}
                onChange={handleLanguageChange}
                sx={{ ml: 1, color: 'white', bgcolor: 'rgba(255,255,255,0.1)', borderRadius: 1 }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="ro">Română</MenuItem>
            </Select>
          </Typography>
        </Container>
      </Box>
  );
};

export default Footer;
