import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import labels from "./labels";

// Utility functions
const isAdult = (birthDate) => {
  const birthDateObj = new Date(birthDate);
  const today = new Date();
  const age = today.getFullYear() - birthDateObj.getFullYear();
  const month = today.getMonth() - birthDateObj.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDateObj.getDate())) {
    return age - 1;
  }
  return age;
};

const containsNumbers = (str) => /\d/.test(str);

const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

const RegisterPage = () => {

  const [language, setLanguage] = useState('en');
  const [currentLabels, setCurrentLabels] = useState(labels[language]);

  useEffect(() => {
    // Recupera la lingua salvata o usa quella del browser
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
    const initialLanguage = savedLanguage || browserLanguage;
    setLanguage(initialLanguage);
    setCurrentLabels(labels[initialLanguage]);
  }, []);

  useEffect(() => {
    // Aggiorna le etichette quando cambia la lingua salvata dal Footer
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && savedLanguage !== language) {
      setLanguage(savedLanguage);
      setCurrentLabels(labels[savedLanguage]);
    }
  }, [language]);

  const [formData, setFormData] = useState({
    phone_number: '',
    last_name: '',
    first_name: '',
    birth_date: '',
    email: '',
    password: '',
    password_confirmation: '',
    accepts_terms: false,
  });

  const [formErrors, setFormErrors] = useState({});
  const [feedbackMessage, setFeedbackMessage] = useState({ type: '', text: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    const errors = { ...formErrors };

    if (name === 'phone_number') {
      const phoneRegex = /^[0-9]{9,15}$/;
      if (!value || !phoneRegex.test(value)) {
        errors.phone_number = currentLabels.phone_number_error;
      } else {
        delete errors.phone_number;
      }
    }

    if (name === 'birth_date') {
      if (!value || isAdult(value) < 18) {
        errors.birth_date = currentLabels.birth_date_error;
      } else {
        delete errors.birth_date;
      }
    }

    if (name === 'first_name') {
      if (!value || containsNumbers(value)) {
        errors.first_name = currentLabels.first_name_error;
      } else {
        delete errors.first_name;
      }
    }

    if (name === 'last_name') {
      if (!value || containsNumbers(value)) {
        errors.last_name = currentLabels.last_name_error;
      } else {
        delete errors.last_name;
      }
    }

    if (name === 'email') {
      if (!value || !validateEmail(value)) {
        errors.email = currentLabels.email_error;
      } else {
        delete errors.email;
      }
    }

    if (name === 'password') {
      if (!value || value.length < 8) {
        errors.password = currentLabels.password_error;
      } else {
        delete errors.password;
      }
    }

    if (name === 'password_confirmation') {
      if (value !== formData.password) {
        errors.password_confirmation = currentLabels.password_confirmation_error;
      } else {
        delete errors.password_confirmation;
      }
    }

    if (name === 'accepts_terms') {
      if (!checked) {
        errors.accepts_terms = currentLabels.accepts_terms_error;
      } else {
        delete errors.accepts_terms;
      }
    }

    setFormErrors(errors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (Object.keys(formErrors).length > 0) return;

    try {
      const response = await axios.post('/api/register', formData, {
        withCredentials: true,
      });

      setFeedbackMessage({ type: 'success', text: response.data.message });
      setTimeout(() => {
        navigate('/products');
      }, 1000);
    } catch (error) {
      setFeedbackMessage({
        type: 'error',
        text: error.response ? error.response.data.error : currentLabels.registration_failed,
      });
    }
  };

  return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">{currentLabels.register_title}</h2>
          {feedbackMessage.text && (
              <div
                  className={`text-sm p-3 mb-4 text-white rounded ${
                      feedbackMessage.type === 'success' ? 'bg-green-500' : 'bg-red-500'
                  }`}
              >
                {feedbackMessage.text}
              </div>
          )}
          <form onSubmit={handleSubmit}>
            {/* Phone Number */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.phone_number}</label>
              <input
                  type="text"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.phone_number ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.phone_number && <p className="text-sm text-red-500">{formErrors.phone_number}</p>}
            </div>

            {/* Last Name */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.last_name}</label>
              <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.last_name ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.last_name && <p className="text-sm text-red-500">{formErrors.last_name}</p>}
            </div>

            {/* First Name */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.first_name}</label>
              <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.first_name ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.first_name && <p className="text-sm text-red-500">{formErrors.first_name}</p>}
            </div>

            {/* Birth Date */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.birth_date}</label>
              <input
                  type="date"
                  name="birth_date"
                  value={formData.birth_date}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.birth_date ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.birth_date && <p className="text-sm text-red-500">{formErrors.birth_date}</p>}
            </div>

            {/* Email */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.email}</label>
              <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.email ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.email && <p className="text-sm text-red-500">{formErrors.email}</p>}
            </div>

            {/* Password */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.password}</label>
              <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.password ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.password && <p className="text-sm text-red-500">{formErrors.password}</p>}
            </div>

            {/* Confirm Password */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">{currentLabels.confirm_password}</label>
              <input
                  type="password"
                  name="password_confirmation"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                  className={`mt-1 w-full px-3 py-2 border rounded ${
                      formErrors.password_confirmation ? 'border-red-500' : 'border-gray-300'
                  }`}
              />
              {formErrors.password_confirmation && (
                  <p className="text-sm text-red-500">{formErrors.password_confirmation}</p>
              )}
            </div>

            {/* Accept Terms */}
            <div className="flex items-center mb-4">
              <input
                  type="checkbox"
                  name="accepts_terms"
                  checked={formData.accepts_terms}
                  onChange={handleChange}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring focus:ring-blue-200"
              />
              <label className="ml-2 text-sm text-gray-700">
                {currentLabels.accept_terms} <a href="/terms-and-conditions" className="text-blue-600">{currentLabels.terms_and_conditions}</a>
              </label>
            </div>
            {formErrors.accepts_terms && <p className="text-sm text-red-500">{formErrors.accepts_terms}</p>}

            {/* Submit Button */}
            <button
                type="submit"
                className="w-full px-4 py-2 font-medium text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none"
            >
              {currentLabels.register_button}
            </button>
          </form>

          <p className="mt-4 text-sm text-center">
            {currentLabels.already_have_account}{' '}
            <Link to="/login" className="text-blue-600 hover:underline">
              {currentLabels.login}
            </Link>
          </p>
        </div>
      </div>
  );
};

export default RegisterPage;
