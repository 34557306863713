import React, { useEffect, useRef, useState } from "react";
import labels from "./labels";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactDOM from 'react-dom/client';
import {
    Avatar,
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { convertStatsKeys, convertToCents, generateImageCanvas, getTeamLogo, truncateDecimal } from './Utility';
import {useNavigate} from "react-router-dom";

const MatchSelector = () => {
    const [teamData, setTeamData] = useState([]);
    const [selectedLeague, setSelectedLeague] = useState("");
    const [selectedTeam1, setSelectedTeam1] = useState("");
    const [selectedTeam2, setSelectedTeam2] = useState("");
    const [showSelector, setShowSelector] = useState(true);
    const [showStats, setShowStats] = useState(false);
    const [isQuery, setIsQuery] = useState(false);
    const [error, setError] = useState(null);
    const [matchStats, setMatchStats] = useState(null);
    const [centMode, setCentMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const fetchDone = useRef(false);

    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);

    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    useEffect(() => {
        const browserLanguage = navigator.language.startsWith("ro") ? "ro" : "en";
        setLanguage(browserLanguage);



        const urlParams = new URLSearchParams(window.location.search);
        const teamA = urlParams.get("teamA");
        const teamB = urlParams.get("teamB");
        const league = urlParams.get("league");

            const fetchTeamData = async () => {
                try {
                    const response = await fetch("/api/config");
                    const data = await response.json();
                    setTeamData(data);
                    setLoading(false);
                } catch (error) {
                    setError(error.message);
                    setLoading(false);
                }
            };

            fetchTeamData();

        if (teamA && teamB) {
            setSelectedLeague(league);
            setSelectedTeam1(teamA);
            setSelectedTeam2(teamB);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setIsQuery(true);
            handleShowStats(teamA, teamB, league);
            setShowSelector(false); // Nascondi il selettore
        }
    }, []);

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleLeagueChange = (event) => {
        setSelectedLeague(event.target.value);
        setSelectedTeam1("");
        setSelectedTeam2("");
        setShowStats(false);
        setMatchStats(null);
    };

    const handleTeam1Change = (event) => {
        setSelectedTeam1(event.target.value);
        setShowStats(false);
        setMatchStats(null);
    };

    const handleTeam2Change = (event) => {
        setSelectedTeam2(event.target.value);
        setShowStats(false);
        setMatchStats(null);
    };

    const handleShowStats = async (teamA = selectedTeam1, teamB = selectedTeam2, league = selectedLeague) => {
        if (teamA && teamB) {
            try {
                const response = await fetch(`/api/team-averages?teamA=${teamA}&teamB=${teamB}&league=${league}`);

                const data = await response.json();
                setMatchStats(data);
                setShowStats(true);
            } catch (error) {
                setError(error.message);
            }
        }
    };

    const leagues = [...new Set(teamData.map((team) => team.league))];
    const filteredTeams = selectedLeague ? teamData.filter((team) => team.league === selectedLeague) : teamData;

    const statsRef = useRef(null);

    const handleDownloadImage = () => {
        let reactDom = ReactDOM;
        generateImageCanvas(reactDom, statsRef, selectedTeam1, selectedTeam2).then((canvas) => {
            const link = document.createElement('a');
            const formattedDate = new Date().toLocaleDateString('it-IT').replace(/\//g, '_');
            const formattedTeam1 = formatName(selectedTeam1);
            const formattedTeam2 = formatName(selectedTeam2);

            link.href = canvas.toDataURL('image/png');
            link.download = `stats_${formattedTeam1}_${formattedTeam2}_${formattedDate}.png`;
            link.click();
        }).catch((error) => {
            console.error('Errore durante il download dell\'immagine:', error);
        });
    };

    const formatName = (name) => {
        return name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
    };

    return (
        <Container
            maxWidth="md"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: 3, // Aggiungi uno spazio sopra
                paddingBottom: 3, // Aggiungi uno spazio sotto
                marginTop: 2, // Distanziamento leggero dall'alto
                paddingY: { xs: 3, md: 0 }, // Rimuovi l'altezza del contenitore per non centrare
            }}
        >

            {showStats && matchStats && (
                <Box ref={statsRef}>
                    {isQuery &&(
                        <Box sx={{ width: "100%", textAlign: "left", marginBottom: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                component="a"
                                href="/"
                                sx={{
                                    borderRadius: "12px",
                                    padding: "8px 20px",
                                    backgroundColor: "#1976d2", // Primary blue color
                                    "&:hover": {
                                        backgroundColor: "#1565c0", // Darker blue on hover
                                    },
                                    display: "flex",
                                    alignItems: "center",
                                    boxShadow: 3,
                                }}
                            >
                                <ArrowBackIcon sx={{ color: "white", marginRight: 1 }} />
                                {currentLabels.back}
                            </Button>
                        </Box>
                    )}
                    <Box textAlign="center" mt={2} sx={{ mx: "auto", width: { xs: "90%", sm: "70%" }, marginTop: 4 }}>
                        <Grid container spacing={2} alignItems="center" justifyContent="center" wrap="nowrap">
                            <Grid item sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>1</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].A, 1)}%</Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>X</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].X, 1)}%</Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>2</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].B, 1)}%</Typography>
                            </Grid>

                            <Grid item sx={{ textAlign: "center", ml: 4 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>GG</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].GG, 1)}%</Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center", ml: 4 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>OV (2.5)</Typography>
                                <Typography>{truncateDecimal(matchStats.generalOdds[0].OV, 1)}%</Typography>
                            </Grid>
                        </Grid>
                        <br />
                    </Box>

                    <Box mt={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%"
                                }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            src={getTeamLogo(selectedTeam1, teamData)}
                                            alt={selectedTeam1}
                                            variant="square"
                                            sx={{ width: 35, height: 35, marginRight: 1 }}
                                        />
                                        <Typography variant="h9" sx={{ fontWeight: 'bold' }}>
                                            {selectedTeam1}
                                        </Typography>
                                    </Box>
                                </Typography>
                                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" colSpan={2}>{currentLabels.statistics}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(convertStatsKeys(matchStats.homeAverages, currentLabels.statsMap)[0]).map(([stat, value]) => (
                                                <TableRow key={stat}>
                                                    <TableCell>{stat}</TableCell>
                                                    <TableCell align="right">{convertToCents(value, centMode)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6" sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%"
                                }}>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar
                                            src={getTeamLogo(selectedTeam2, teamData)}
                                            alt={selectedTeam2}
                                            variant="square"
                                            sx={{ width: 35, height: 35, marginRight: 1 }}
                                        />
                                        <Typography variant="h9" sx={{ fontWeight: 'bold' }}>
                                            {selectedTeam2}
                                        </Typography>
                                    </Box>
                                </Typography>
                                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" colSpan={2}>{currentLabels.statistics}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(convertStatsKeys(matchStats.awayAverages, currentLabels.statsMap)[0]).map(([stat, value]) => (
                                                <TableRow key={stat}>
                                                    <TableCell>{stat}</TableCell>
                                                    <TableCell align="right">{convertToCents(value)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <br />
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default MatchSelector;