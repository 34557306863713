import {CircularProgress} from "@mui/material";
import html2canvas from "html2canvas";

export const generateImageCanvas = (reactDom, statsRef, selectedTeam1, selectedTeam2) => {
    return new Promise((resolve, reject) => {
        const statsElement = statsRef.current;

        if (!statsElement) {
            console.error('statsElement is not defined');
            reject('statsElement is not defined');
            return;
        }

        // Overlay per il caricamento
        const overlay = document.createElement('div');
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.backgroundColor = 'white';
        overlay.style.zIndex = '10400';

        // Contenitore per CircularProgress
        const loadingContainer = document.createElement('div');
        loadingContainer.style.position = 'fixed';
        loadingContainer.style.top = '50%';
        loadingContainer.style.left = '50%';
        loadingContainer.style.transform = 'translate(-50%, -50%)';
        loadingContainer.style.zIndex = '10500';

        const root = reactDom.createRoot(loadingContainer);
        root.render(<CircularProgress size={60} style={{ color: '#1976d2' }} />);
        document.body.appendChild(overlay);
        document.body.appendChild(loadingContainer);

        // Salva e modifica gli stili per creare l’immagine
        const originalBodyStyle = document.body.style.cssText;
        const originalStatsStyle = statsElement.style.cssText;
        const formattedDate = new Date().toLocaleDateString('it-IT');

        // Contenitore per titolo e data
        const overlayDiv = document.createElement('div');
        overlayDiv.style.display = 'flex';
        overlayDiv.style.flexDirection = 'column';
        overlayDiv.style.alignItems = 'center';
        overlayDiv.style.padding = '0px';
        overlayDiv.style.zIndex = '9500';
        overlayDiv.style.marginTop = '30px'; // Modificato da '40px' a '30px' per sollevarlo del 20%

        // Titolo
        const titleDiv = document.createElement('div');
        titleDiv.innerText = `${selectedTeam1} VS ${selectedTeam2}`;
        titleDiv.style.fontSize = '33px';
        titleDiv.style.fontWeight = 'bold';
        titleDiv.style.textAlign = 'center';
        titleDiv.style.padding = '20px';
        titleDiv.style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
        titleDiv.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';

        // Data
        const dateDiv = document.createElement('div');
        dateDiv.innerText = formattedDate;
        dateDiv.style.fontSize = '25px';
        dateDiv.style.fontWeight = '400';
        dateDiv.style.textAlign = 'center';
        dateDiv.style.marginTop = '10px';
        dateDiv.style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
        dateDiv.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';

        overlayDiv.appendChild(titleDiv);
        overlayDiv.appendChild(dateDiv);

        const logoDiv = document.createElement('img');
        logoDiv.src = 'logo.png';
        logoDiv.style.position = 'absolute';
        logoDiv.style.top = '50%';
        logoDiv.style.left = '50%';
        logoDiv.style.width = '700px';
        logoDiv.style.height = '700px';
        logoDiv.style.borderRadius = '0%';
        logoDiv.style.zIndex = '10000';
        logoDiv.style.transform = 'translate(-50%, -50%)';
        logoDiv.style.opacity = '0.1';
        logoDiv.style.filter = 'hue-rotate(30deg) saturate(1.5) brightness(1.1)';

        statsElement.style.position = 'relative';
        statsElement.style.width = '600px';
        statsElement.style.height = '620px';
        statsElement.style.textAlign = 'center';
        statsElement.prepend(overlayDiv);
        statsElement.appendChild(logoDiv);

        const grids = statsElement.querySelectorAll('.MuiGrid-root');
        grids.forEach(grid => {
            grid.style.display = 'inline-block';
        });

        document.body.style.overflow = "hidden";

        // Generazione immagine
        html2canvas(statsElement, {
            useCORS: true,
            allowTaint: false,
            scale: 2,
        }).then((canvas) => {
            resolve(canvas);
            // Rimuove overlay, logo, e ripristina stili originali
            statsElement.removeChild(overlayDiv);
            statsElement.removeChild(logoDiv);
            document.body.style.cssText = originalBodyStyle;
            statsElement.style.cssText = originalStatsStyle;
            document.body.removeChild(overlay);
            root.unmount();
            document.body.removeChild(loadingContainer);
            grids.forEach(grid => {
                grid.style.width = '';
                grid.style.display = '';
                grid.style.verticalAlign = '';
                grid.style.margin = '';
            });
        }).catch((error) => {
            document.body.removeChild(overlay);
            root.unmount();
            document.body.removeChild(loadingContainer);
            reject(error);
        });
    });
};

// Funzione per ottenere il logo di una squadra
export const getTeamLogo = (teamName, teamData) => {
    return teamData.find((team) => team.team === teamName)?.logo;
};

// Funzione per convertire un valore in centesimi
export const convertToCents = (value, centMode) => {
    return centMode ? parseFloat(value).toFixed(2) : value;
};

// Funzione per il download dell'immagine
export const handleDownloadImage = (reactDom, statsRef, selectedTeam1, selectedTeam2) => {
    generateImageCanvas(reactDom, statsRef, selectedTeam1, selectedTeam2).then((canvas) => {
        const link = document.createElement('a');
        const formattedDate = new Date().toLocaleDateString('it-IT').replace(/\//g, '_');
        const formattedTeam1 = formatName(selectedTeam1);
        const formattedTeam2 = formatName(selectedTeam2);

        link.href = canvas.toDataURL('image/png');
        link.download = `stats_${formattedTeam1}_${formattedTeam2}_${formattedDate}.png`;
        link.click();
    }).catch((error) => {
        console.error('Errore durante il download dell\'immagine:', error);
    });
};

// Funzione per formattare il nome
const formatName = (name) => {
    return name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');
};

// Funzione per troncare i decimali
export const truncateDecimal = (value, decimals) => {
    const number = parseFloat(value);
    if (isNaN(number)) return value;
    return Number(number.toFixed(decimals));
};

// Funzione per convertire le chiavi delle statistiche
export const convertStatsKeys = (arr, mappaDescrizioni) => {
    if (arr.length === 0) return arr;
    const obj = arr[0];
    const nuovoOggetto = {};
    for (const [chiave, valore] of Object.entries(obj)) {
        const nuovaChiave = mappaDescrizioni[chiave] || chiave;
        nuovoOggetto[nuovaChiave] = valore;
    }
    return [nuovoOggetto];
};


export default {generateImageCanvas, getTeamLogo, convertToCents, handleDownloadImage, truncateDecimal, convertStatsKeys};