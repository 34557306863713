import React, {useEffect, useState} from 'react';
import labels from "./labels";

const TermsConditionsPage = () => {

    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);

    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center py-10 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">Termeni și Condiții pentru GizzoBet.com</h1>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">1. Introducere</h2>
                <p className="text-gray-700 mb-4">
                    Acest document stabilește termenii și condițiile de utilizare a serviciilor oferite de GizzoBet.com. Prin accesarea și utilizarea site-ului nostru, acceptați acești termeni și condiții în totalitate. Dacă nu sunteți de acord cu oricare parte a acestora, vă rugăm să nu utilizați serviciile noastre.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">2. Descrierea serviciilor</h2>
                <p className="text-gray-700 mb-4">
                    GizzoBet.com oferă statistici sportive, cum ar fi analiza mediei de goluri, șuturi și alte informații relevante. Nu oferim servicii de pariuri sportive și nu promovăm astfel de activități. Scopul serviciilor noastre este exclusiv informativ, iar utilizarea acestora este responsabilitatea dumneavoastră.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">3. Limitarea responsabilității</h2>
                <p className="text-gray-700 mb-4">
                    GizzoBet.com nu garantează niciun rezultat financiar sau de altă natură în urma utilizării statisticilor oferite.
                </p>
                <p className="text-gray-700 mb-4">
                    Nu suntem răspunzători pentru pierderi de orice tip, inclusiv pierderi financiare, care ar putea rezulta din utilizarea informațiilor furnizate pe site.
                </p>
                <p className="text-gray-700 mb-4">
                    Utilizatorii înțeleg și acceptă că deciziile pe care le iau, inclusiv deciziile legate de pariuri sportive, sunt luate exclusiv pe propria răspundere.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">4. Abonamente și plăți</h2>
                <p className="text-gray-700 mb-4">
                    Serviciile sunt disponibile pe bază de abonament. Prețul abonamentului este specificat pe site.
                </p>
                <p className="text-gray-700 mb-4">
                    Rambursarea contravalorii abonamentelor nu este posibilă, indiferent de motiv.
                </p>
                <p className="text-gray-700 mb-4">
                    Pentru anularea abonamentului, trebuie să trimiteți un email la gizzoro.bettings@gmail.com, indicând motivul solicitării.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">5. Distribuirea neautorizată a conținutului</h2>
                <p className="text-gray-700 mb-4">
                    Conținutul oferit de GizzoBet.com este protejat de Legea nr. 8/1996 privind dreptul de autor și drepturile conexe.
                </p>
                <p className="text-gray-700 mb-4">
                    Este strict interzisă:
                </p>
                <ul className="list-inside list-disc text-gray-700 mb-4">
                    <li>Distribuirea conținutului către terți prin capturi de ecran, copiere, partajare sau alte mijloace.</li>
                    <li>Utilizarea conținutului în alte scopuri decât cele permise, inclusiv pentru promovarea activităților de pariuri sportive.</li>
                </ul>

                <p className="text-gray-700 mb-4">
                    Penalizare pentru încălcări:
                </p>
                <p className="text-gray-700 mb-4">
                    Orice utilizator care încalcă această prevedere este obligat să achite o penalizare contractuală de 5.000 de euro. Această penalizare este justificată prin:
                </p>
                <ul className="list-inside list-disc text-gray-700 mb-4">
                    <li>Pierderile financiare cauzate de distribuirea neautorizată, care poate afecta semnificativ vânzările serviciilor oferite de GizzoBet.com.</li>
                    <li>Posibilitatea ca informațiile distribuite neautorizat să fie utilizate în mod greșit, ducând la interpretări incorecte despre scopul și calitatea serviciului nostru.</li>
                    <li>Necesitatea de a proteja integritatea conținutului și reputația site-ului GizzoBet.com.</li>
                </ul>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">6. Drepturi și obligații</h2>
                <p className="text-gray-700 mb-4">
                    GizzoBet.com are dreptul de a modifica în orice moment conținutul și structura site-ului, precum și acești termeni și condiții, cu notificarea prealabilă a utilizatorilor.
                </p>
                <p className="text-gray-700 mb-4">
                    Utilizatorii sunt obligați să utilizeze serviciile conform prevederilor legale și acestor termeni.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">7. Politica de confidențialitate</h2>
                <p className="text-gray-700 mb-4">
                    Datele personale colectate prin intermediul site-ului vor fi utilizate în conformitate cu Regulamentul General privind Protecția Datelor (GDPR). Detalii despre politica de confidențialitate sunt disponibile pe pagina dedicată de pe site.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">8. Legea aplicabilă</h2>
                <p className="text-gray-700 mb-4">
                    Acești termeni și condiții sunt guvernați de legislația din România. Orice litigiu apărut va fi soluționat de instanțele competente din România.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">9. Contact</h2>
                <p className="text-gray-700 mb-4">
                    Pentru orice întrebări sau solicitări legate de acești termeni și condiții, ne puteți contacta la adresa de email: gizzoro.bettings@gmail.com.
                </p>

                <p className="text-sm text-gray-600 mt-4">Ultima actualizare: 14/12/2024.</p>
            </div>
        </div>
    );
};

export default TermsConditionsPage;
