import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Button, Box, Alert, CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import labels from "./labels"; // Importa axios

const SuccessPayment = () => {

  const [language, setLanguage] = useState('en');
  const [currentLabels, setCurrentLabels] = useState(labels[language]);

  useEffect(() => {
    // Recupera la lingua salvata o usa quella del browser
    const savedLanguage = localStorage.getItem('language');
    const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
    const initialLanguage = savedLanguage || browserLanguage;
    setLanguage(initialLanguage);
    setCurrentLabels(labels[initialLanguage]);
  }, []);

  useEffect(() => {
    // Aggiorna le etichette quando cambia la lingua salvata dal Footer
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && savedLanguage !== language) {
      setLanguage(savedLanguage);
      setCurrentLabels(labels[savedLanguage]);
    }
  }, [language]);

  const [loading, setLoading] = useState(true); // Per mostrare un caricamento durante la verifica
  const [verificationStatus, setVerificationStatus] = useState(null); // Per memorizzare il risultato della verifica
  const [error, setError] = useState(null); // Per memorizzare eventuali errori durante la chiamata API
  const [isVerified, setIsVerified] = useState(false); // Flag per evitare la duplicazione della chiamata API
  const [invoiceUrl, setInvoiceUrl] = useState(null); // Stato per memorizzare l'URL della fattura

  // Ottieni i parametri della query dall'URL usando useLocation
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id'); // Estrai session_id dai parametri della query

  useEffect(() => {
    if (sessionId && !isVerified) { // Verifica che sessionId esista e che la verifica non sia già stata fatta
      setLoading(true); // Imposta il caricamento a true per la chiamata iniziale

      const verifyPayment = async () => {
        try {
          const response = await axios.post(
              '/api/verify',
              { sessionId },
              { withCredentials: true } // Invia i cookie con la richiesta
          );

          if (response.status === 200) {
            setVerificationStatus(response.data.status); // Memorizza lo stato della verifica
            setIsVerified(true); // Imposta il flag di verifica
            setInvoiceUrl(response.data.invoice); // Memorizza l'URL della fattura
          } else {
            throw new Error('Payment verification failed');
          }
        } catch (error) {
          setError(error.message); // Gestisci eventuali errori
        } finally {
          setLoading(false); // Imposta a false una volta che la chiamata è terminata
        }
      };

      // Invoca l'API dopo 1 secondo (1000 millisecondi)
      const timer = setTimeout(() => {
        verifyPayment(); // Esegui la chiamata API
      }, 1000);

      // Pulizia del timer quando il componente viene smontato o se i parametri cambiano
      return () => clearTimeout(timer);
    }
  }, [sessionId, isVerified]); // Aggiungi isVerified per evitare la duplicazione della chiamata

  const copyToClipboard = () => {
    if (invoiceUrl) {
      navigator.clipboard.writeText(invoiceUrl); // Copia l'URL negli appunti
      alert('Invoice URL copied to clipboard!');
    }
  };

  return (
      <Container maxWidth="sm" sx={{ paddingTop: 4 }}>
        <Box textAlign="center">
          {loading ? (
              <CircularProgress />
          ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  {currentLabels.payment_successful}
                </Typography>
                <Typography variant="h6" paragraph>
                  {currentLabels.payment_success_description}
                </Typography>
                <Typography variant="body1" paragraph>
                  {currentLabels.payment_success_instructions}
                </Typography>

                {verificationStatus ? (
                    <Alert severity="success" sx={{ marginBottom: 2 }}>
                      {currentLabels.payment_verified}
                    </Alert>
                ) : error ? (
                    <Alert severity="error" sx={{ marginBottom: 2 }}>
                      {error}
                    </Alert>
                ) : null}

                {invoiceUrl && (
                    <Box sx={{ marginBottom: 2 }}>
                      <Typography variant="h6">{currentLabels.view_invoice}</Typography>
                      <TextField
                          fullWidth
                          value={invoiceUrl}
                          variant="outlined"
                          disabled
                          sx={{ marginBottom: 2 }}
                      />
                      <Button variant="contained" onClick={copyToClipboard}>
                        {currentLabels.copy_invoice_url}
                      </Button>
                    </Box>
                )}

                <Alert severity="info" sx={{ marginBottom: 2 }}>
                  <strong>{currentLabels.important}</strong> {currentLabels.cancel_auto_renewal}
                  <strong>{currentLabels.support_email}</strong>.
                </Alert>
                <Button variant="contained" color="primary" href="/">
                  {currentLabels.go_to_dashboard}
                </Button>
              </>
          )}
        </Box>
      </Container>
  );
};

export default SuccessPayment;
